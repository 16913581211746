<template>
  <div class="garbage-container">
    <p class="garbage-title">可还原{{expireDay}}天内删除的文件/文件夹</p>
    <t-pagination
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :pages="pages">
      <el-table
        :data="list"
        slot="table"
        class="t-tabel"
        style="width: 100%"
        border>
        <!-- <template slot="empty">
        </template> -->
        <div slot="empty" class="nothing-box" style="margin: 100px 0;">
			<i class="not-icon small" />
			<p class="not-tit">暂无内容</p>
        </div>
        <el-table-column
          v-for="(v, i) in labelList"
          :key="i"
          :label="v.label"
          :min-width="v.width" :prop="v.key" :sortable="v.sortable">
          <template slot-scope="scope">
            <span>{{ scope.row[v.key] || NOT }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="156">
          <template slot-scope="scope">
			<span @click="reduction(scope.row)" class="btn">还原</span>
			<span @click="del(scope.row)" class="btn del">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </t-pagination>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import pagination from '@/mixins/pagination'
import message from '@/mixins/message'

export default {
  mixins: [pagination, message],
  data() {
    return {
      expireDay:0,
      labelList: [
        {
          label: '文件名称',
          width: 400,
          key: 'name'
        }, {
          label: '移除人',
          width: 120,
          key: 'deleteUser'
        }, {
          label: '文件类型',
          width: 120,
          key: 'fileTypeName'
        }, 
		{
          label: '移除时间',
          width: 160,
          key: 'deleteDate',
		  sortable:true
        }, {
          label: '剩余保存时间',
          width: 156,
          key: 'expireTime',
		  sortable:true
        }
      ]
    }
  },
  watch: {
    curGroupId(val) {
      val && this.init()
    }
  },
  methods: {
    ...mapMutations(['SETMSGDIALOG']),
    init() {
      this.getList()
    },
    // 获取订单
    getList() {
      const { pageParams } = this

      this.$api.garbage.getGarbageList({
        ...pageParams
      }).then(res => {
        this.expireDay = res.expireDay
        this.list = res.items || []
        this.pages = res.page || null
      })
    },
    // 还原
    reduction(params) {
      const { id, isFile } = params
      const api = isFile ? 'reductionFile' : 'reductionDirectory'
      this.SETMSGDIALOG({
        title: '还原',
        message: '是否还原？',
		type:"error",
		width:"439px",
        commit: () => {
          this.$api.garbage[api]({
            id
          }).then(() => {
            this.success({
				message: '还原成功',
				duration:1000
            })
            this.getList()
            this.SETMSGDIALOG(null)
          })
        }
      })
    },
    // 删除
    del(params) {
      const { id, isFile } = params
      const api = isFile ? 'delFile' : 'delDirectory'

      this.SETMSGDIALOG({
        title: '删除',
        message: '是否彻底删除？删除后将无法还原',
		type:"error",
		width:"439px",
        commit: () => {
          this.$api.garbage[api]({
            id
          }).then(() => {
            this.success({
				message: '彻底删除成功',
				duration:1000
            })
            this.getList()
            this.SETMSGDIALOG(null)
          })
        }
      })
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
  .garbage-container {
    height: 100%;
    background: #fff;
    padding: 24px;
    border-radius:16px;
	overflow-y: scroll;
	&::-webkit-scrollbar{width:0;}	
    .garbage-title {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9AAAB7;
      line-height: 17px;
	  margin-bottom: 18px;
    }
	.btn{
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #006AFF;
		line-height: 20px;
		cursor: pointer;
		&.del{
			color: #D81414;
		}
		+.btn{
			margin-left: 24px;
		}
	}
  }
</style>
